import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import RoomSelector from "./floor_selector";
import {AppConfig} from "../config";
import UserService from "../utils/auth";

const EditableRoomSelector = ({
                                  value, header, editable = true,
                                  onSave = () => {
                                  }
                              }) => {
    const token = UserService.getToken()

    const [data, setData] = useState(value)
    const [isEditMode, setIsEditMode] = useState(false)
    const [occupiedRooms, setOccupiedRooms] = useState([])

    useEffect(() => {
        fetch(AppConfig.getApiUrl('/clients/'), {
            headers: {"Authorization": `Bearer ${token}`}
        })
            .then(response => response.json())
            .then(response => {
                const rooms = response.map(client => client.room).filter(x => x !== null).map(x => Number(x))
                setOccupiedRooms(rooms)
            })
            .catch(error => console.error(error));
    }, [])

    const editButton = (
        <Button variant="outline-light" onClick={
            () => {
                if (isEditMode) onSave(data)
                setIsEditMode(!isEditMode)
            }
        }>
            {isEditMode ? "Сохранить" : "Изменить"}
        </Button>
    )

    useEffect(() => {
        setData(value)
    }, [value]);

    const handleChange = (value) => {
        if (value) setData(String(value))
    }

    return (
        <Card border="secondary" className={"card-outline-row"}>
            <Form>
                <Form.Group as={Row} >
                    <Form.Label column sm="3">{header}:</Form.Label>
                    <Col sm={7} style={{
                        display: isEditMode ? "block" : "none",
                        opacity: isEditMode ? 1 : 0
                    }} className={"home-row"}>
                        <RoomSelector
                            minRoomNumber={10}
                            maxRoomNumber={50}
                            floorSize={8}
                            occupiedRooms={occupiedRooms}
                            selectedRoom={value}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col sm={7} style={{display: isEditMode ? "none" : "block"}}>
                        <Form.Control value={data} plaintext readOnly className={"content-text"}/>
                    </Col>
                    {editable && <Col className={"me-auto"}>{editButton}</Col>}
                </Form.Group>
            </Form>
        </Card>
    )
}

export default EditableRoomSelector;