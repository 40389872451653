import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import UserService from './utils/auth'
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";

const renderApp = () => {
    ReactDOM.createRoot(document.getElementById('root')).render(<DevSupport ComponentPreviews={ComponentPreviews}
                                                                            useInitialHook={useInitial}
    >
        <App/>
    </DevSupport>)
};
UserService.initKeycloak(renderApp)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
