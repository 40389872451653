import React from 'react';

import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import UserService from "../utils/auth";
import {useNavigate, useNavigation} from "react-router-dom";

const Logo = () => {
    return (
        <Navbar.Brand href="/">Halloween 2024</Navbar.Brand>
    )
}

const CabinetNavItem = () => {
    return (
        <Nav>
            <NavDropdown title={UserService.getUsername()}>
                <NavDropdown.Item href="/home">Кабинет</NavDropdown.Item>
                <NavDropdown.Item onClick={UserService.doLogout}>Выход</NavDropdown.Item>
            </NavDropdown>

        </Nav>
    )
}

const NavBarItem = ({item}) => {
    const navigate = useNavigate();

    return (
        <Nav.Link onClick={() => navigate(item.link)} className="px-3">{item.text}</Nav.Link>
    )
}

const NavItemsList = ({navLinks}) => {
    return (
        <Nav className="m-auto">
            {
                navLinks.map(
                    item => (
                        <NavBarItem item={item} key={item.link}/>
                    )
                )
            }
        </Nav>
    )
}

const Header = () => {
    let navLinks = [
        {text: 'О мире', link: '/about'},
        {text: 'О празднике', link: '/faq'},
        {text: 'Тайная тыква', link: '/pumpkin'},
        {text: 'Список постояльцев', link: '/clients'},
    ]
    return (
        <Navbar expand="lg" className="bg-body-tertiary" sticky="top">
            <Container>
                <Logo/>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <NavItemsList navLinks={navLinks}/>
                    <CabinetNavItem/>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;