import React, {useEffect} from 'react';
import Header from "../components/header";
import PageContent from "../components/page_content";
import {useLocation} from "react-router-dom";
import UserService from "../utils/auth";

const Page = ({pageInfo, children}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const location = useLocation();
    useEffect(() => {
        UserService.updateToken()
    }, [location])
    return (
        <div className={"App main"}>
            <Header/>
            <PageContent
                name={pageInfo ? pageInfo.name : null}
                quotes={pageInfo ? pageInfo.quote : null}
                data={pageInfo ? pageInfo.data : null}
            >
                {children}
            </PageContent>
        </div>
    );
};

export default Page;