import React from 'react';
import {Col, Row} from "react-bootstrap";
import "../style/info_pages.css"

const ContentParagraph = ({row}) => {
    const dataCol = (
        <Col className={"info-main-col"}>
            {row.data.map((p, index) =>
                <div
                    key={index}
                    style={{"paddingBottom": "15px"}}
                    className={`info-main-${p.kind}`}
                    onClick={p.link ? () => window.location=`${p.link}` : null}
                >
                    {p.data}
                </div>
            )}
        </Col>
    )

    const imageCol = row.image && (
        <Col sm={6} className={"info-main-image info-main-col"} style={{
            backgroundImage: `url(${row.image.url})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover'
        }}/>
    )

    return (
        <div className={"info-paragraph-container"}>
            {
                row.header &&
                <Row>
                    <div style={{"paddingBottom": "15px"}} className={`info-main-header`}>{row.header}</div>
                </Row>
            }
            <Row>
                {(row.image && row.image.alignment === 'left') && imageCol}
                {dataCol}
                {(row.image && row.image.alignment === 'right') && imageCol}
            </Row>
        </div>
    );
};

export default ContentParagraph;