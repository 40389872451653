import React from 'react';
import './style/main.css'
import AppRouter from "./components/router";

function App() {
    return (
        <AppRouter/>
    );
}

export default App;
