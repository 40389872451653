import React from 'react';
import "../style/welcome.css"
import {Button} from "react-bootstrap";
import {Navigate} from "react-router-dom";
import UserService from "../utils/auth";

const WelcomePage = () => {
    if (UserService.isLoggedIn()) {
        return (
            <Navigate to={"/about"} replace/>
        )
    }
    const data = {
        'header': [
            'Дорогой друг!'
        ],
        'main': [
            'Двери Отеля “Хазбин” открываются для празднования самого страшного праздника года – Хэллоуина, и мы будем рады видеть тебя среди наших гостей.',
            'Надевай свой самый жуткий костюм и скорее бронируй номер – мы подготовили самые лучшие комнаты с отличным видом на Пентаграмм Сити!'
        ],
        'sign': [
            'с любовью и до скорой встречи',
            'администрация отеля “Хазбин”.'
        ]
    }
    const rawData = Object.entries(data).map(value => ({'key': value[0], 'value': value[1]}))

    return (
        <div className="App main welcome-div">
            <div className="welcome-div-content div-opacity-black p-5">
                {
                    rawData.map(val => (
                        <div className={`welcome-text-${val.key}`}>
                            {val.value.map(row => (<div>{row}</div>))}
                        </div>
                    ))
                }

                <div className="welcome-div-buttons">
                    <div className="py-1">
                        <Button className={"btn-dark"} onClick={() => {
                            UserService.register()
                        }}>Забронировать номер</Button>
                    </div>
                    <div className="py-1">
                        <Button variant="secondary" onClick={() => {
                            UserService.doLogin().then(() => window.location.reload());
                        }}>У меня уже есть номер</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WelcomePage;