import React, {useEffect, useState} from 'react';
import Page from "./page";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import UserService from "../utils/auth";
import "../style/info_pages.css"
import "../style/main.css"
import "../style/home.css"
import "../style/room_select.css"
import {AppConfig} from "../config";
import EditableRoomSelector from "../components/editable_element";

const EditableString = ({
                            value, header, type = "text", editable = true,
                            onSave = () => {
                            }
                        }) => {
    const [data, setData] = useState(value)
    const [isEditMode, setIsEditMode] = useState(false)

    const handleSave = () => {
        onSave(data)
    }

    const editButton = (
        <Button variant="outline-light" onClick={
            () => {
                if (isEditMode) onSave(data)
                setIsEditMode(!isEditMode)
            }
        }>
            {isEditMode ? "Сохранить" : "Изменить"}
        </Button>
    )

    useEffect(() => {
        setData(value)
    }, [value]);

    return (
        <Card border="secondary" className={"card-outline-row"}>
            <Form>
                <Form.Group as={Row}>
                    <Form.Label column sm="3">{header}:</Form.Label>
                    <Col sm={7}
                         style={{
                             display: isEditMode ? "block" : "none",
                             opacity: isEditMode ? 1 : 0
                         }}
                    >
                        <Form.Control
                            type={type}
                            value={data}
                            onChange={(e) => {
                                setData(e.target.value)
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    if (isEditMode) {
                                        e.preventDefault()
                                        setIsEditMode(!isEditMode)
                                        handleSave()
                                    }
                                }
                                if (e.key === 'Escape') {
                                    if (isEditMode) {
                                        e.preventDefault()
                                        setData(value)
                                        setIsEditMode(!isEditMode)
                                    }
                                }
                            }}
                        />
                    </Col>
                    <Col sm={7} style={{display: isEditMode ? "none" : "block"}}>
                        <div className={"content-text"}>
                            {data}
                        </div>
                    </Col>
                    {editable && <Col className={"me-auto"}>{editButton}</Col>}
                </Form.Group>
            </Form>
        </Card>
    )
}


const HomePage = () => {
    const token = UserService.getToken()
    let defaultData = {
        "name": "...",
        "room": 0,
        "facts": [
            null, null, null, null, null
        ],
        "reason": "..."
    }
    const [data, setData] = useState(defaultData)

    useEffect(() => {
        fetch(AppConfig.getApiUrl('/clients/me/'), {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(response => {
                setData(response)
            })
            .catch(error => console.error(error));
    }, []);

    const updateClient = (body) => {
        fetch(AppConfig.getApiUrl('/clients/me/'), {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(body)
        })
            .then(response => response.json())
            .then(response => {
                response['facts'].sort((a, b) => a && b && a["id"] > b["id"])
                setData(response)
            })
            .catch(error => console.error(error));
    }

    const [mutableData, setMutableData] = useState(data)
    const setValue = (key, index = null) => {
        return (value) => {
            if (index === null) mutableData[key] = value
            else mutableData[key][index]["data"] = value
            setMutableData({...mutableData})
            updateClient(mutableData)
        }
    }

    let pageInfo = {
        name: "Личный Кабинет",
    }

    useEffect(() => {
        setMutableData(data)
    }, [data])

    const uploadPhoto = (e) => {
        const formData = new FormData();
        formData.append(
            "file",
            e.target.files[0],
            e.target.files[0].name
        );
        fetch(AppConfig.getApiUrl('/clients/me/photo/'), {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${token}`
            },
            body: formData
        })
            .then(response => response.json())
            .then(response => {
                response['facts'].sort((a, b) => a && b && a["id"] > b["id"])
                setData(response)
            })
            .catch(error => console.error(error));
    }

    return (
        <Page pageInfo={pageInfo}>
            <Row>
                <Col sm={3}>
                    <Card variant="outline-light" className={"client-card-image-container card-image-container"}>
                        <div className={"profile-picture"} style={{backgroundImage: `url(${data.photo})` || null}}>
                            <h1 className="upload-icon">
                                <i className="fa fa-plus fa-2x" aria-hidden="true"></i>
                            </h1>
                            <input
                                className="file-uploader"
                                type="file"
                                onChange={uploadPhoto}
                                accept="image/*"
                            />
                        </div>
                    </Card>
                </Col>
                <Col>
                    <EditableString
                        header={"Имя персонажа"}
                        value={data.name || ""}
                        onSave={setValue("name")}
                        editable
                    />
                    <EditableRoomSelector
                        header={"Номер комнаты"}
                        value={data["room"] || ""}
                        onSave={setValue("room")}
                        editable
                    />
                    {
                        data['facts']
                            .sort((a, b) => a && b && a["id"].localeCompare(b["id"]))
                            .map((fact, index) =>
                                <EditableString
                                    key={index}
                                    header={`Факт №${index + 1}`}
                                    value={fact ? fact.data : ""}
                                    onSave={setValue("facts", index)}
                                    editable/>
                            )
                    }
                    <EditableString
                        header={"Почему попал в ад"}
                        value={data["reason"] || ""}
                        onSave={setValue("reason")}
                        editable
                    />
                </Col>
            </Row>
            <Row className={"divider"}/>
            <Row>
                <div className={"home-info-div"}>
                    Дорогой постоялец, перед тобой анкета, которую тебе необходимо заполнить для карточки постояльца.
                    Как только ты начнешь ее заполнять, она попадет в раздел “Список постояльцев”, где все смогут
                    ознакомиться с информацией о тебе, а ты с информацией о других гостях, заселившихся в Отель Хазбин.
                </div>
                <div className={"home-info-div"}>
                    Ты можешь выбрать любую комнату комнату с видом на Пентаграмм Сити, загрузить свое фото и заполнить
                    факты о себе. Что может быть в этих фактах? Все, что ты посчитаешь нужным указать: от интересов и
                    предпочтений до событий твоей жизни и привычек. И не забудь рассказать, почему ты попал в Ад.
                </div>
                <div className={"home-info-div"}>
                    Удачного заполнения!
                </div>
            </Row>
        </Page>
    );
};
export default HomePage;