import React, {useEffect, useState} from 'react';
import Page from "./page";
import {Card, Col, Modal, Row} from "react-bootstrap";
import "../style/info_pages.css"
import UserService from "../utils/auth";
import {AppConfig} from "../config";

const ClientModalRow = ({title, data}) => {
    return (
        <Row className={"client-modal-row"}>
            <Col sm={4}>{title}</Col>
            <Col>{data}</Col>
        </Row>
    )
}

const ClientModal = ({client, show, onHide}) => {
    const facts = client.facts || []
    const defaultPhoto = "/static/no_photo_logo.png"
    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{client.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{"padding": 0}}>
                <Row className={"client-modal-body"}>
                    <Col sm={3} className={"client-card-image-container client-modal-image-container"}>
                        <img src={client.photo || defaultPhoto} className={"client-card-image"} alt={"No photod"}/>
                    </Col>
                    <Col>
                        <ClientModalRow title={"Имя персонажа:"} data={client.name}/>
                        <ClientModalRow title={"Номер комнаты:"} data={client.room}/>
                        {
                            facts.map((fact,index) =>
                                <ClientModalRow key={index} title={`Факт №${index+1}: `} data={fact.data}/>
                            )
                        }
                        <ClientModalRow title={"За что попал в ад:"} data={client.reason}/>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

const ClientCard = ({client}) => {
    const defaultPhoto = "/static/no_photo_logo.png"
    const defaultName = "Новый постоялец"

    const [showModal, setShowModal] = useState(false)
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);


    return (
        <Col sm={3}>
            <ClientModal client={client} show={showModal} onHide={handleClose}/>
            <Card className={"client-card"} onClick={handleShow}>
                <Card.Body className={"client-card-image-container"}>
                    <img src={client.photo || defaultPhoto} className={"client-card-image"} alt={"No photod"}/>
                </Card.Body>
                <Card.Footer className={"client-card-text"}>
                    <div>{client.name || defaultName}</div>
                    <div>{"Комната №" + client.room || "Комната еще не выбрана"}</div>
                </Card.Footer>
            </Card>
        </Col>
    )
}

const ClientsPage = () => {
    const token = UserService.getToken()
    let pageInfo = {
        name: "Наши постояльцы",
        quote: [
            {
                data: "Итак! Раз уж у нас появился новый постоялец, думаю, будет важно узнать друг друга, поэтому мы поиграем в одну игру. Повторяйте за мной. Меня зовут Чарли. *хлопает* Люблю я песни исполнять. *хлопает* И будет замечательно, если друг друга мы будем знать!",
                image: "/static/clients-background.png",
                owner: 'Чарли Морнингстар'
            }
        ],
    }

    const [data, setData] = useState([])

    useEffect(() => {
        fetch(AppConfig.getApiUrl('/clients/'), {
            headers: {"Authorization": `Bearer ${token}`}
        })
        .then(response => response.json())
        .then(response => setData(response))
        .catch(error => console.error(error));
    }, []);

    return (
        <Page pageInfo={pageInfo}>
            <Row>
                {data.map(client =>
                    <ClientCard
                        key={client.id}
                        client={client}
                    />
                )}
            </Row>
        </Page>
    );
};

export default ClientsPage;