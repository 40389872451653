import React from 'react';
import Page from "./page";
import ContentParagraph from "../components/content_paragraph";

const FaqPage = () => {
    let pageInfo = {
        name: "О Празднике",
        quote: [
            {
                data: "Что ж, я думаю, самое время для твоего первого урока - \"Как извиняться\". Первый шаг на пути искупления - это умение признавать свою неправоту! Почему бы тебе не попробовать?",
                image: '/static/faq-quote.png',
                owner: 'Чарли Морнингстар'
            }
        ],
        data: [
            {
                kind: "paragraph",
                data: [
                    {
                        "kind": "subheader",
                        "data": "📅 Когда?"
                    },
                    {
                        "kind": "text", "data": "02.11.2024"
                    },
                ]
            },
            {
                kind: "paragraph",
                data: [
                    {
                        "kind": "subheader",
                        "data": "⏰ Во сколько?"
                    },
                    {
                        "kind": "text", "data": "18:00-19:00 – сбор гостей."
                    },
                    {
                        "kind": "text", "data": "20:00 – начало основной программы праздника"
                    },
                ]
            },
            {
                kind: "paragraph",
                data: [
                    {
                        "kind": "subheader",
                        "data": "📍 Где?"
                    },
                    {
                        "kind": "text",
                        "data": "Королев, мкр. Юбилейный, ул. Маяковского, д27а. Ориентир – красные ворота."
                    },
                ]
            },
            {
                kind: "paragraph",
                data: [
                    {
                        "kind": "subheader",
                        "data": "🎃 Тематика:"
                    },
                    {
                        "kind": "text", "data": "Вечеринка в Аду в Отеле “Хазбин”."
                    },
                ]
            },
            {
                kind: "paragraph",
                data: [
                    {
                        "kind": "subheader",
                        "data": "🔮 Кем мне быть?"
                    },
                    {
                        "kind": "text",
                        "data": "Костюм любого героя или существа, который тебе нравится. Конкретных рамок нет – мы за отсутствие ограничений: ты можешь адаптировать выбранного персонажа под тематику праздника. Если ты переживаешь о повторении костюмов, перед заполнением анкеты постояльца напиши любому из организаторов о том, кем планируешь быть этим вечером. Если твоему образу требуется обувь, бери ее с собой, только проверь, чтобы она была чистой."
                    },
                ]
            },
            {
                kind: "paragraph",
                data: [
                    {
                        "kind": "subheader",
                        "data": "🍾🍿 Что кушать и пить?"
                    },
                    {
                        "kind": "text",
                        "data": "На празднике будет фуршетное меню из небольших закусок и снеков. Основное блюдо мы будем заказывать. Для определения основного блюда, пожалуйста, пройди опрос после перехода в общую беседу."
                    },
                    {
                        "kind": "text",
                        "data": "Также на празднике будут два тематических коктейля – ты всегда можешь попросить тебе их приготовить. Остальной алкоголь, который ты будешь пить, в зависимости от своих предпочтений, приноси с собой."
                    },
                    {
                        "kind": "text",
                        "data": "❗️ Если у тебя есть аллергия на какие-то продукты, пожалуйста, сообщи об этом организаторам.❗️"
                    },
                ],
            },
            {
                kind: "paragraph",
                data: [
                    {
                        kind: "subheader",
                        data: "🎉 Что будет на празднике?"
                    },
                    {
                        kind: "text",
                        data: "Вас ждет интересная конкурсная программа, памятные призы, отличная музыка, фотозона и, конечно же, прекрасная компания. В этом году мы хотим достичь максимального погружения и атмосферности, поэтому будем очень рады, если ты будешь придерживаться черт выбранного тобой персонажа и вживешься в роль до конца конкурсной части. Обдумывай каждый шаг и действие с умом, не бойся проявлять хитрость и смекалку, а также будь готов ко всем испытаниям, которые встретятся на пути!"
                    },
                    {
                        "kind": "text",
                        "data": "И если у тебя есть желание приготовить тематическую вкусняшку или веселую активность  – мы будем только рады :)"
                    },
                ]
            },
            {
                kind: "paragraph",
                data: [
                    {
                        "kind": "subheader",
                        "data": "👥 Команда организаторов"
                    },
                    {
                        "kind": "link",
                        "data": "📸 Анна Пушина",
                        "link": "https://t.me/jhnrdb"
                    },
                    {
                        "kind": "link",
                        "data": "💭 Виктория Шварева",
                        "link": "https://t.me/vikishh"
                    },
                    {
                        "kind": "link",
                        "data": "🖥️ Александр Рахов",
                        "link": "https://t.me/Rahovski"
                    },
                    {
                        "kind": "link",
                        "data": "💾 Андрей Пушин",
                        "link": "https://t.me/UberSchwein"
                    },
                ]
            },
            {
                kind: "paragraph",
                data: [
                    {
                        kind: "subheader",
                        data: "💬 Ссылка на тг-канал:"
                    },
                    {
                        kind: "link",
                        data: "📻 Hazbin Radio Broadcast 📻",
                        link: "https://t.me/HazbinRadioBroadcast"
                    }
                ]
            },
        ],
    }
    return (
        <Page pageInfo={pageInfo}>
            {pageInfo.data.map((row, index) => <ContentParagraph row={row} key={index}/>)}
        </Page>
    );
};

export default FaqPage;