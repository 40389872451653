import React, {useEffect, useRef, useState} from 'react';
import {Card, Col, Container, Row} from "react-bootstrap";
import "../style/info_pages.css"
import "../style/main.css"

const PageHeader = ({pageName, quotes}) => {
    const header = (
        <Card.Header>
            {pageName}
        </Card.Header>
    )
    const quoteChangeInterval = 6000
    const quoteChangeDuration = 1000
    const quoteChangeTimeout = quoteChangeDuration / 2
    const [quoteIndex, setQuoteIndex] = useState(null)
    const [quote, setQuote] = useState(quotes ? quotes[0] : null)
    const updateQuoteRef = useRef();

    useEffect(() => {
        if (quotes && quotes.length > 1) {
            const interval = setInterval(function () {
                setQuoteIndex(((quoteIndex || 0) + 1) % quotes.length)
            }, quoteChangeInterval)
            return () => clearInterval(interval);
        }
    }, [quoteIndex])

    useEffect(() => {
        // Skipping the initial render. TODO: use a better solution from https://stackoverflow.com/questions/53179075/with-useeffect-how-can-i-skip-applying-an-effect-upon-the-initial-render
        if (quoteIndex === null) {
            return;
        }
        updateQuoteRef.current.animate(
            {
                opacity: [0, 1, 1, 1, 0]
            },
            quoteChangeDuration
        );
        setTimeout(() => setQuote(quotes[quoteIndex]), quoteChangeTimeout)
    }, [quoteIndex]);

    const body = (
        <Card.Body
            className={"content-header-container"}
            style={{backgroundImage: quote && quote.image ? `url(${quote.image})` : null}}>
            {quotes && <div ref={updateQuoteRef} className={"content-header-noize"}></div>}
            {quotes && quote.data.split('\n').map((x, index) =>
                <div key={index} className={quote && quote.image && "content-header-quote-container"}>
                    <div key={x} className={"content-header-quote"} style={{color: quote && quote.image ? null : "black"}}>
                        {x}
                    </div>
                    {quote.owner &&
                        <div className={"content-header-quote content-header-quote-owner"}>
                            — {quote.owner}
                        </div>
                    }
                </div>
            )}
        </Card.Body>
    )
    return (
        <Card
            className={"p-3 content-header " + (quotes ? "content-header-full" : "content-header-only")}
            border={quotes ? "inherit" : "secondary"}
        >
            {quotes ? header : pageName}
            {quotes && body}
        </Card>
    )
}

const PageContent = ({name, quotes, data, children}) => {
    const pageHeader = (
        <Row className="my-5">
            <Col>
                <PageHeader pageName={name} quotes={quotes}/>
            </Col>
        </Row>
    )
    const needPageHeader = name || quotes
    return (
        <Container className="content-div div-opacity-black px-3 py-3">
            {needPageHeader && pageHeader}
            <Row className={"info-main-row"}>
                <Col className="content-text">
                    {children}
                </Col>
            </Row>
        </Container>
    );
};

const EmptyRow = (row) => {
    return (
        <div></div>
    )
}

export default PageContent;