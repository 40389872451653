import React, {useEffect} from 'react';
import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";
import RootPage from "../pages/root";
import WelcomePage from "../pages/welcome";
import PrivateRoute from "./private_route";
import AboutPage from "../pages/about";
import Logout from "../pages/logout";
import FaqPage from "../pages/faq";
import HomePage from "../pages/home";
import ClientsPage from "../pages/clients";
import PumpkinPage from "../pages/pumpkin";
import UserService from "../utils/auth";

const AppRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<RootPage/>}/>
                <Route exact path="/welcome" element={<WelcomePage/>}/>
                <Route path="/about" element={<PrivateRoute> <AboutPage/> </PrivateRoute>}/>
                <Route path="/faq" element={<PrivateRoute> <FaqPage/> </PrivateRoute>}/>
                <Route path="/pumpkin" element={<PrivateRoute> <PumpkinPage/> </PrivateRoute>}/>
                <Route path="/clients" element={<PrivateRoute> <ClientsPage/> </PrivateRoute>}/>
                <Route path="/home" element={<PrivateRoute> <HomePage/> </PrivateRoute>}/>
                <Route path="/logout" element={<PrivateRoute> <Logout/> </PrivateRoute>}/>
            </Routes>
        </BrowserRouter>
    );
};

export default AppRouter;