import React from 'react';
import UserService from "../utils/auth";

const AppLogout = () => {
    const postLogoutRedirectUri = window.location.origin + "/welcome"
    return (
        UserService.doLogout({redirectUri: postLogoutRedirectUri})
    );
};

export default AppLogout;