import React, {useEffect, useState} from 'react';
import "../style/room_select.css"


const FloorSelector = ({floor, setFloor}) => {
    return (
        <div className={"floor-selector-container"}>
            <div className={"floor-selector"}>
                <div className={"arrow"} onClick={() => setFloor(floor + 1)}>▲</div>
                <div>{floor}</div>
                <div className={"arrow"} onClick={() => setFloor(floor - 1)}>▼</div>
            </div>
        </div>
    )
}


const Room = ({number, isOccupied = false, isSelected = false, isActive = false, setActiveRoom = null}) => {
    const isFreeRoom = !isOccupied && !isSelected && !isActive
    let classes = [
        "room",
        (isFreeRoom ? "free-room" : null),
        (isSelected ? "selected-room" : null),
        (isActive ? "active-room" : null)
    ]
    return (
        <div key={number} className={classes.join(' ')} onClick={() => {
            setActiveRoom && setActiveRoom(number)
        }}>
            {number}
        </div>
    )
}

const RoomSet = ({
                     minRoomNumber,
                     size,
                     occupiedRooms = [],
                     selectedRoom = null,
                     activeRoom = null,
                     setActiveRoom = null
                 }) => {
    return (
        <div className={"room-container"}>
            {
                [...Array(size).keys()].map((num) =>
                    <Room
                        key={minRoomNumber + num}
                        number={minRoomNumber + num}
                        isOccupied={occupiedRooms.includes(minRoomNumber + num)}
                        isSelected={selectedRoom && (Number(selectedRoom) === (minRoomNumber + num))}
                        isActive={activeRoom && Number(activeRoom) === (minRoomNumber + num)}
                        setActiveRoom={setActiveRoom}
                    />
                )
            }
        </div>
    )
}

const Floor = ({
                   minRoomNumber,
                   floorSize,
                   occupiedRooms = [],
                   selectedRoom = null,
                   activeRoom = null,
                   setActiveRoom = null
               }) => {
    return (
        <div className={"floor-container"}>
            <div className={"col-3"}>
                <div className={"room stairway"}>
                    Лестница
                </div>
            </div>
            <div className={"col-9 hall"}>
                <RoomSet
                    minRoomNumber={minRoomNumber}
                    size={floorSize / 2}
                    occupiedRooms={occupiedRooms}
                    selectedRoom={selectedRoom}
                    activeRoom={activeRoom}
                    setActiveRoom={setActiveRoom}
                />
                <div className={"room-container"}></div>
                <RoomSet
                    minRoomNumber={floorSize / 2 + minRoomNumber}
                    size={floorSize / 2}
                    occupiedRooms={occupiedRooms}
                    selectedRoom={selectedRoom}
                    activeRoom={activeRoom}
                    setActiveRoom={setActiveRoom}
                />
            </div>
        </div>
    )
}

const RoomSelector = ({
                          defaultFloor = 1,
                          floorSize = 8,
                          minRoomNumber = 1,
                          maxRoomNumber = 100,
                          occupiedRooms = [],
                          selectedRoom = null,
                          onChange = null
                      }) => {
    const [floorNumber, setFloorNumber] = useState(defaultFloor)
    const [activeRoom, setActiveRoom] = useState(null)
    const maxFloor = (maxRoomNumber - minRoomNumber) / floorSize + 1
    const changeFloorHandler = (floor) => {
        if (floor > 0 && floor < maxFloor) {
            setFloorNumber(floor)
        }
    }

    useEffect(() => {
        if (onChange) onChange(activeRoom)
    }, [activeRoom, onChange]);

    return (
        <div className={"container room-selector"}>
            <FloorSelector floor={floorNumber} setFloor={changeFloorHandler}/>
            <Floor
                minRoomNumber={minRoomNumber + ((floorNumber - 1) * floorSize)}
                floorSize={floorSize}
                occupiedRooms={occupiedRooms}
                selectedRoom={selectedRoom}
                activeRoom={activeRoom}
                setActiveRoom={setActiveRoom}
            />
            <div>

            </div>
        </div>
    );
};

export default RoomSelector;