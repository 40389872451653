import React from 'react';
import Page from "./page";
import ContentParagraph from "../components/content_paragraph";

const AboutPage = () => {
    let pageInfo = {
        name: "О мире",
        quote: [
            {
                data: "Привет! Я Чарли! Дамы и господа, я открываю ПЕРВЫЙ в своём роде отель для реабилитации грешников! Добро пожаловать в Отель Счастья! Ты просто влюбишься в это место!",
                image: '/static/about-quote-charlie.png',
                owner: 'Чарли Морнингстар'
            },
            {
                data: "Добро пожаловать в отель Хазбин, ложный путь к искуплению! Основанный 5 дней назад помешанной дочерью Люцифера Шарлоттой Морнингстар! Доверь свою судьбу в её неопытные руки пока она пытается решить проблемы со своим папочкой исправляя тебя!",
                image: '/static/about-quote-alastor.png',
                owner: 'Аластор'
            }
        ],
        data: [
            {
                kind: "paragraph",
                header: "Добро пожаловать в отель “Хазбин”!",
                data: [
                    {
                        "kind": "text",
                        "data": "Прежде чем поведать историю нашего отеля, мы хотели бы ознакомить тебя, дорогой постоялец, с историей нашего страшно славного мира – Ада."
                    },
                    {
                        "kind": "text", "data": "Итак, приступим!"
                    },
                ]
            },
            {
                kind: "paragraph",
                header: "Ад",
                image: {
                    'alignment': 'left',
                    'url': '/static/about-hell.png',
                },
                data: [
                    {
                        "kind":
                            "text",
                        "data":
                            "Ад состоит из Семи Кругов: Гордыня, Гнев, Чревоугодие, Жадность, Похоть, Зависть, Лень  " +
                            "– климат у каждого круга особенный: например, у круга Гордыни красное небо, а у круга Похоти – голубое. " +
                            "Земли Ада полны различий: их наполняют крупные города, пустыни и непроходимые джунгли. " +
                            "Климат в Аду вспыльчивый, серный и подверженный засухе, однако, " +
                            "нередко на его территории проходят кислотные дожди."
                    },
                    {
                        "kind": "text",
                        "data": "История происхождения:"
                    },
                    {
                        "kind": "text",
                        "data":
                            "Тысячи лет назад, после того, как Люцифер подарил человечеству свободу, предложив Еве Плод Познания, " +
                            "на Земле образовался Ад, в который проникло зло. " +
                            "Люцифера и его возлюбленную – Лилит, в наказание за неповиновение, Рай сослал в Ад. " +
                            "Своими песнями Лилит подарила силу демоническому роду и Ад начал процветать. " +
                            "Боясь, что Ад станет сильнее, Рай наслал на него ежегодные истребления, сокращая численность грешников, " +
                            "в попытках предотвратить восстание жителей Ада против жителей Рая."
                    }
                ]
            },
            {
                kind: "paragraph",
                header: "Круг Гордыни и Пентаграмм Сити",
                image: {
                    alignment: 'right',
                    url: '/static/pentagram-city.png',
                },
                data: [
                    {
                        "kind": "text",
                        "data":
                            "Круг Гордыни – первый и самый главный круг Ада. " +
                            "В круге Гордыни расположена столица Ада – Пентаграмм Сити, в котором был основан отель “Хазбин”."
                    },
                    {
                        "kind": "text",
                        "data":
                            "Пентаграмм Сити – крупнейший город и столица Ада, разделенный на множество секций и районов. " +
                            "Его жителями являются демоны, грешники и те, кто был рожден в Аду."
                    },
                    {
                        "kind": "text",
                        "data":
                            "В Пентаграмм Сити множество достопримечательностей: казино, ночные клубы, бордели, рестораны и отели. " +
                            "В этих местах демоны ведут свой бизнес: продают оружие, наркотики, а также заключают контракты, " +
                            "обеспечивающие им статус, влияние и силу в Аду."
                    },
                    {
                        "kind": "text",
                        "data": "Советуем посетить вам “Закусочную дьявола” и торговый центр Рози, расположенный в городе Каннибалов!"
                    },
                ],
            },
            {
                kind: "paragraph",
                header: "Отель “Хазбин”",
                image: {
                    alignment: 'left',
                    url: '/static/hotel.png',
                },
                data: [
                    {
                        "kind": "text",
                        "data": "Дорогой постоялец, добро пожаловать в отель “Хазбин” – место счастья и искупления!"
                    },
                    {
                        "kind": "text",
                        "data":
                            "Ранее здание отеля принадлежало королевской семье Морнингстар, но было заброшенно. " +
                            "Чарли Морнингстар, дочь властителя Ада Люцифера, взяла здание под свое крыло, основав в нем отель “Счастья”, " +
                            "который впоследствии был переименован деловым партнером по продвижению отеля, Аластором, в “Хазбин” отель."
                    },
                    {
                        "kind": "text",
                        "data":
                            "Отель Хазбин – высокое здание; в нем семь этажей, пять из которых – гостевые. " +
                            "Состояние отеля на данный момент аварийное, однако, он по-прежнему выглядит величественно, " +
                            "с гордостью и заботой встречая каждого гостя."
                    },
                    {
                        "kind": "text",
                        "data":
                            "Основная миссия отеля “Хазбин”: реабилитация грешников посредством выбора правильного пути " +
                            "для достижения главной цели – искупления грехов."
                    },
                    {
                        "kind": "quote",
                        "data":
                            "“Мы верим, что каждый житель Ада, даже самый кровожадный убийца, способен совершать добрые поступки, " +
                            "и заслуживает прожить лучшую жизнь в Раю!” – Чарли Морнингстар."
                    },
                    {
                        "kind": "text",
                        "data":
                            "Разумеется, не все жители Ада поддерживают идею Отеля: привыкнув наращивать силу в Аду, " +
                            "стремясь к максимальной власти, они считают ее смехотворной, но администрация Хазбина уверена, " +
                            "что может вас переубедить, и показать, что добро – лучше зла."
                    }
                ]
            },
            {
                kind: "paragraph",
                header: "Готовы ли вы дать нам шанс и встать на путь добра?",
                data: []
            }
        ],
    }
    return (
        <Page pageInfo={pageInfo}>
            {pageInfo.data.map((row, index) => <ContentParagraph row={row} key={index}/>)}
        </Page>
    );
};

export default AboutPage;