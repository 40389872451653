import React from 'react';
import {Navigate} from "react-router-dom";
import UserService from "../utils/auth";

const RootPage = () => {
    const redirectPage = UserService.isLoggedIn() ? "/about" : "/welcome"
    return (
        <Navigate to={redirectPage} replace/>
    );
};

export default RootPage;