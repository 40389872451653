import React from 'react';
import Page from "./page";
import ContentParagraph from "../components/content_paragraph";

const PumpkinPage = () => {
    let pageInfo = {
        name: "Тайная тыква",
        quote: [
            {
                data: "В этом отеле абсолютно запрещено создавать оружие. Никто не пытается тебя убить. Все здесь ведут себя хорошо, потому что хотят, чтобы ты чувствовал себя желанным гостем.",
                image: '/static/pumpkin-quote.png',
                owner: 'Чарли Морнингстар'
            }
        ],
        data: [
            {
                kind: "paragraph",
                data: [
                    {
                        "kind": "subheader",
                        "data": "Привет, дорогие постояльцы!"
                    },
                    {
                        "kind": "text",
                        "data": "Традиционно проводим в нашем отеле обмен подарками перед Днём Всех Святых☠️"
                    },
                    {
                        "kind": "text",
                        "data": "Регистрируйтесь по ссылке ниже и пишите свои пожелания относительно подарка. Ближе к празднику будет проведена жеребьевка, и вы узнаете, кто будет вашим подопечным в этом году!"
                    },
                    {
                        "kind": "link",
                        "data": "https://santa-secret.ru/box/4bubxW/card?join=2tsbg97IxNtWbbW",
                        "link": "https://santa-secret.ru/box/4bubxW/card?join=2tsbg97IxNtWbbW"
                    },
                    {
                        "kind": "text",
                        "data": "Раскрыть, кому вы дарите подарок, можно будет только на самом празднике, пока держим интригу. Переписываться с подопечным и задавать ему уточняющие вопросы можно после проведения жеребьевки через анонимные сообщения в личном кабинете на сайте."
                    },
                    {
                        "kind": "text",
                        "data": "Лимит по стоимости подарков в этом году - 1500р."
                    },
                    {
                        "kind": "text",
                        "data": "По всем вопросам обращайтесь ко мне!"
                    },

                    {
                        "kind": "text",
                        "data": "С любовью,"
                    },
                    {
                        "kind": "link",
                        "data": "Чарли",
                        "link": "https://t.me/vikishh"
                    }
                ]
            }
        ]
    }
    return (
        <Page pageInfo={pageInfo}>
            {pageInfo.data.map((row, index) => <ContentParagraph row={row} key={index}/>)}
        </Page>
    );
};

export default PumpkinPage;